import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { newsletterSignUp } from "../../constants";

const Header = () => {
  return (
    <React.Fragment>
      <Navbar bg="light" variant="light">
        <Link to="/">
          <Navbar.Brand style={{ cursor: "pointer" }}>Hatha Lisa</Navbar.Brand>
        </Link>
        <Nav className="container-fluid">
          <NavDropdown title="Yoga">
            <NavDropdown.Item as={Link} to="/classes" eventKey="1">
              Classes & Workshops
            </NavDropdown.Item>

            <NavDropdown.Item as={Link} to="/retreats" eventKey="2">
              Retreats
            </NavDropdown.Item>

            <NavDropdown.Item as={Link} to="/videos" eventKey="3">
              Videos
            </NavDropdown.Item>

            <NavDropdown.Item
              eventKey="4"
              onClick={() =>
                window.open("https://anchor.fm/hathalisa", "_blank")
              }
            >
              Meditation
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link as={Link} to="/about">
            About
          </Nav.Link>

          <Nav.Item className="ml-auto" id="newsletter-button">
            <Button
              variant="outline-secondary"
              onClick={() => {
                window.location = newsletterSignUp;
              }}
            >
              Newsletter
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
