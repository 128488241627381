import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import { liveStreamLink, newsletterSignUp } from "../../constants";

import "./style.scss";

import ClassesImage from "../../img/classes.jpg";
import RetreatsImage from "../../img/retreats.jpg";
import VideosImage from "../../img/videos.jpg";

const Home = () => {
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <div className="homeImage headerImage"></div>
      <Container fluid style={{ backgroundColor: "#a7ad85" }}>
        <Row className="centeredRow" style={{ paddingTop: "50px" }}>
          <h2>Supporting you on your yoga journey.</h2>
        </Row>
        <Row className="centeredRow" style={{ marginTop: "20px" }}>
          <p>
            We are all on a journey to get to know ourselves. Through{" "}
            <strong>YOGA</strong> we can learn to enjoy and deepen this journey.
          </p>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Row
            style={{
              marginTop: "50px",
              paddingBottom: "50px",
              maxWidth: "1000px",
            }}
            lg={3}
          >
            <Col xs={6} md={4}>
              <Link to="/classes">
                <Button bsPrefix="homeButton">
                  <Image src={ClassesImage} fluid roundedCircle />
                  <h5 className="homeTitle">Classes & Workshops</h5>
                </Button>
              </Link>
            </Col>
            <Col xs={6} md={4}>
              <Link to="/retreats">
                <Button bsPrefix="homeButton">
                  <Image src={RetreatsImage} fluid roundedCircle />
                  <h5 className="homeTitle">Retreats</h5>
                </Button>
              </Link>
            </Col>
            <Col xs={6} md={4}>
              <Link to="/videos">
                <Button bsPrefix="homeButton">
                  <Image src={VideosImage} fluid roundedCircle />
                  <h5 className="homeTitle">Videos</h5>
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: "100px",
          }}
        >
          <h3 style={{ paddingBottom: "30px" }}>Quick Access</h3>
          <Button
            variant="secondary"
            style={{
              margin: 10,
            }}
            href={liveStreamLink}
          >
            Join the Live Stream
          </Button>
          <Button
            variant="secondary"
            style={{ margin: 10 }}
            onClick={() => {
              window.location = newsletterSignUp;
            }}
          >
            Sign Up for Newsletter
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Home;
